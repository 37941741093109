<template>
  <b-card no-body class="m-0">
    <b-card-body class="m-0 pt-0 pb-0">
      <p class="float-left mb-0" v-if="Array.isArray(documentData)" style="padding-top: 6px;">
        Showing {{ documentData.length }} items in {{ category }}.
      </p>
      <div class="float-right">
        <b-button
          variant="primary"
          size="sm"
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          v-b-modal.modal-upload-enrollment
        >
          <feather-icon icon="UploadIcon" />
          Upload
        </b-button>
      </div>

      <p class="text-left float-left" v-if="!Array.isArray(documentData)" style="padding-top: 6px;">
        This student has no associated {{ category }} documents.
      </p>

      <div class="clearfix" v-else>
        <div class="clearfix"></div>
        <hr class="pb-0">
        <table class="table table-sm table-responsive table-borderless">
          <tbody>
          <tr v-for="(document, index) in documentData" :key="index">
            <td class="text-left td-img-holder">
              <b-link v-b-tooltip.hover.bottom="document.name" :alt="document.name">
                <b-img
                  class="border border-dark td-img-b-img"
                  :src="document.thumbnailUrl"
                  @click="previewDocument(document)"
                />
              </b-link>
              <br>
              <br>
            </td>
            <td class="text-left td-doc-holder">
              <div class="float-left">
                <h6 class="mb-0">
                  <div v-b-tooltip.hover.bottom="document.name" class="text-truncate td-doc-doc-name">
                    {{ document.name }}
                  </div>
                  <small>
                    {{ moment(document.createdAt).format('L') }}
                  </small>
                </h6>
              </div>

              <div class="float-right">
                <div class="text-right">
                  <b-badge v-if="document.tfv && document.tfv.completed" variant="success" pill>P</b-badge>
                  <b-badge variant="dark" pill>
                    {{ nameToExtension(document.name) }}
                  </b-badge>
                  &nbsp;
                  <small class="text-body">
                    <b-badge variant="primary" size="sm">{{ document.subCategory }}</b-badge>
                  </small>
                </div>

                <b-button-group variant="outline-primary" size="sm" block class="align-bottom mt-1">
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    :disabled="disableDownload"
                    @click="() => previewDocument(document)"
                  >
                    Preview
                  </b-button>

                  <b-dropdown v-ripple.400="'rgba(113, 102, 240, 0.15)'" size="sm" variant="outline-primary">
                    <b-dropdown-item
                      :disabled="disableDownload"
                      @click="() => previewDocument(document)"
                    >
                      Download
                    </b-dropdown-item>
                    <b-dropdown-item v-if="documentHasProof(document)" @click="downloadProof(document)">Voice</b-dropdown-item>
                    <b-dropdown-item v-if="false" disabled>Replace</b-dropdown-item>
                    <b-dropdown-divider/>
                    <b-dropdown-item
                      @click="() => deleteDocument(document.id)">
                      Delete
                    </b-dropdown-item>
                  </b-dropdown>
                </b-button-group>
              </div>
              <div class="clearfix"></div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="clearfix"></div>
    </b-card-body>

    <b-modal
      id="modal-upload-enrollment"
      ref="modal-upload-enrollment"
      title="Select document type"
      cancel-variant="outline-secondary"
      no-stacking
      @ok="onSubCategorySelect"
    >
      <b-form class="m-1">
        <b-form-group label="Choose the type of document you would like to upload." label-for="select-upload-enrollment">
          <v-select
            id="select-upload-enrollment"
            v-model="upload.type"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="selectUploadEnrollmentOptions[category]"
            :selectable="(option) => !option.value.includes('select_value')"
            :clearable="false"
            @input="
              (val) => {
                upload.type = val;
                onSubCategorySelect();
              }
            "
          />
        </b-form-group>
      </b-form>
    </b-modal>

    <b-modal
      id="modal-upload-document"
      ref="modal-upload-document"
      title="Select local file"
      cancel-variant="outline-secondary"
      @ok="openUploadEnroll"
    >
      <b-form class="m-1">
        <b-form-group
          label="Choose the name of the document you will attach. Leave empty to default to the name of the file from your computer."
          label-for="select-upload-name"
        >
          <b-form-input
            id="select-upload-name"
            v-model="upload.fileName"
            :placeholder="`${title(upload.type.label)} #1.pdf`"
          >
          </b-form-input>
        </b-form-group>

        <b-form-group
          label="Choose the document you would like to upload and attach to this student."
          label-for="select-upload-document"
        >
          <b-form-file
            id="select-upload-document-file"
            accept=".jpg, .jpeg, .png, .pdf, .doc"
            :capture="true"
            :autofocus="true"
            @input="onFileSelect"
            v-model="upload.file"
          />
        </b-form-group>
      </b-form>
    </b-modal>

    <b-modal
      id="modal-center"
      :title-html="modalData.title"
      cancel-variant="outline-secondary"
      ok-title="Download"
      ok-title-html="<i class='feather icon-download'></i> Download"
      cancel-title="Close"
      @ok="downloadFile(modalData.document.src)"
      :size="modalData.size"
      v-model="modalData.show"
    >
      <b-card-text>
        <div class="row">
          <div class="col-5 text-truncate">
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="dark"
              size="md"
              @click="expandPreviewModal"
            >
              <feather-icon :icon="modalData.expandIcon" />
            </b-button>
            &nbsp;
            <span v-b-tooltip.hover.bottom="modalData.document.name">
              {{ modalData.document.name }}
            </span>
          </div>

          <div class="col-7">
            <div class="audio-holder" v-show="modalData.document.tfv.proofUrl">
              <audio :src="modalData.document.tfv.proofUrl" controls class="w-100"></audio>
            </div>
          </div>
        </div>

        <hr>

        <viewer
          v-if="modalData.document.imgSrc"
          ref="viewer"
          :options="modalData.imgViewer.options"
          :images="modalData.imgViewer.images"
          class="viewer mt-5 mb-5"
          style="margin-top: 200px;"
          @inited="vViewerInitialized"
        >
          <template #default="scope">
            <figure class="images">
              <div v-for="{source, thumbnail, alt} in scope.images" :key="source" class="image-wrapper">
                <img
                  class="image"
                  :src="thumbnail"
                  :data-source="source"
                  :alt="alt"
                >
              </div>
            </figure>
          </template>
        </viewer>

        <iframe v-else :src="modalData.document.src" class="w-100 doc-preview"></iframe>
      </b-card-text>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BBadge,
  BButton,
  BButtonGroup,
  BCard,
  BCardBody,
  BCardText,
  BDropdown,
  BDropdownDivider,
  BDropdownItem,
  BForm,
  BFormFile,
  BFormGroup,
  BFormInput,
  BImg,
  BLink,
  BModal,
  BRow,
  VBModal,
  VBTooltipPlugin
} from "bootstrap-vue";
import moment from "moment";
import { title } from "@/@core/utils/filter";
import store from "@/store";

import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import studentStoreModule from "@/views/apps/student/studentStoreModule";
import { ref } from "@vue/composition-api";
import router from "@/router";
import fileDownload from "js-file-download";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { component as Viewer } from 'v-viewer';
import acceptedMimeTypes from '@/@fake-db/data/other/mime-types';

export default {
  components: {
    BCard,
    BCardBody,
    BCardText,
    BImg,
    BLink,
    BButton,
    BRow,
    vSelect,
    Ripple,
    BModal,
    VBModal,
    BForm,
    BFormInput,
    BFormFile,
    BFormGroup,
    BBadge,
    BButtonGroup,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    // 3rd Party
    Viewer,
  },
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltipPlugin,
    Ripple,
  },
  props: {
    studentData: {
      type: Object,
      required: true,
    },
    category: {
      type: String,
      required: true,
    },
  },
  data: () => {
    return {
      upload: {
        type: { value: 'select_value', label: 'Select Type' },
        fileName: undefined,
        file: undefined,
      },
      disableDownload: false,
      selectUploadEnrollmentOptions: {
        enrollment: [
          { value: 'select_value', label: 'Select Type' },
          { value: 'coe', label: 'Certificate of Eligibility' },
          { value: 'tac', label: 'Terms and Conditions' },
          { value: 'ef', label: 'Enrollment Form' },
          { value: 'resume', label: 'Resume' },
        ],
        financial: [
          { value: 'select_value', label: 'Select Type' },
          { value: 'invoice', label: 'Invoice' },
          { value: 'receipt', label: 'Receipt' },
          { value: 'quote', label: 'Quote' },
        ],
        placement: [
          { value: 'select_value', label: 'Select Type' },
          { value: 'resume', label: 'Resume' },
          { value: 'me', label: 'Meaningful Employment' },
          { value: 'jol', label: 'Job Offer Letter' },
          { value: 'coc', label: 'Certificate of Completion' },
          { value: 'esr', label: 'Exam Score Report' },
        ],
        transcript: [
          {value: 'select_value', label: 'Select Type'},
          {value: 'transcript', label: 'Transcript'},
          {value: 'toc', label: 'Transfer of Credits'},
        ],
        misc: [
          {value: 'select_value', label: 'Select Type'},
          {value: 'misc', label: 'Misc'},
          {value: 'evidence', label: 'Evidence'},
        ]
      },
      modalData: {
        audioUrl: null,
        title: '<i class=\'feather icon-eye\'></i> Document Preview - %fullName - %id',
        document: {
          category: null,
          id: null,
          name: null,
          tfv: {
            proofUrl: null,
          },
          src: '#',
          imgSrc: '#',
          downloadSrc: null,
        },
        imgViewer: {
          options: {
            inline: true,
            button: false,
            navbar: false,
            title: false,
            toolbar: false,
            tooltip: true,
            movable: true,
            zoomable: true,
            rotatable: true,
            scalable: true,
            transition: true,
            fullscreen: true,
            keyboard: true,
            url: 'data-source',
          },
          images: [],
        },
        size: 'lg',
        show: false,
        expandIcon: 'Maximize2Icon',
      },
    };
  },
  methods: {
    vViewerInitialized(viewer) {
      this.$viewer = viewer;
    },
    nameToExtension(name) {
      if (!name) return name;
      const spl = name.split('.');
      return spl[spl.length - 1];
    },
    async deleteDocument(documentId) {
      const documentResp = await store
        .dispatch('app-student/deleteStudentDocument', {
          studentId: this.studentData.id,
          documentId,
        })
        .then(() => this.refetchData())
        .catch((error) => console.log(`Failed to delete document`, error));
    },
    async onSubCategorySelect(mdl) {
      if (mdl) {
        mdl.preventDefault();
      }

      this.$refs['modal-upload-document'].show();
    },

    async openUploadEnroll() {
      if (!this.upload.type || !this.upload.type.value || this.upload.type.value === 'select_value') {
        return this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Failed to create document.',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: 'Failed to select or invalid subcategory.',
          },
        });
      }

      const formData = new FormData();
      formData.append('document', this.upload.file);

      try {
        const documentResp = await store
          .dispatch('app-student/createStudentDocument', {
            studentId: this.studentData.id,
            payload: {
              name: this.upload.fileName || this.upload.file.name,
              category: this.category,
              subCategory: this.upload.type.value,
            },
          })
          .catch((error) => console.log(`Failed to create document`, error));

        if (documentResp.status !== 201) {
          return this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to create document.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: documentResp.data.message || 'An unknown error has occurred.',
            },
          });
        }

        const document = documentResp.data;

        const uploadResp = await store
          .dispatch('app-student/uploadStudentDocument', {
            studentId: this.studentData.id,
            documentId: document.id,
            formData,
          })
          .catch((error) => console.log(`Failed to upload student document`, error));

        if (uploadResp.status !== 200) {
          return this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to upload document.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: uploadResp.data.message || 'An unknown error has occurred.',
            },
          });
        }

        this.refetchData();
      } catch (e) {
        if (e.response) {
          const { response } = e;

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to create and upload document.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: response.data.message || 'An unknown error has occurred.',
            },
          });
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to create and upload document.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: e.message,
            },
          });
        }
      }
    },

    onFileSelect() {
      console.log(this.upload.file);

      if (!this.upload.fileName) {
        this.upload.fileName = this.upload.file.name;
      }
    },
    documentHasProof(document) {
      return document.tfv && document.tfv.proofUrl;
    },
    async downloadProof(document) {
      if (document.tfv && document.tfv.proofUrl) {
        window.open(document.tfv.proofUrl, '_blank');
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Failed to download',
            icon: 'SettingsIcon',
            variant: 'danger',
            text: 'Document does not have a recording attached',
          },
        });
      }
    },
    async previewDocument(document) {
      this.disableDownload = true;

      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Please wait a moment...',
          icon: 'SettingsIcon',
          variant: 'success',
          text: 'We\'re preparing the document.',
        },
      });

      await store
        .dispatch('app-student/documentStudentDocumentPreview', {
          studentId: this.studentData.id,
          documentId: document.id,
        })
        .then((response) => {
          const { url, mime } = response.data;
          const modalData = this.modalData;

          modalData.document = document;

          if(document.tfv) {
            modalData.audioUrl = document.tfv.proofUrl;
          }

          modalData.title = modalData.title.replace('%fullName', this.studentData.fullName)
            .replace('%id', this.studentData.studentId);

          const contentType = mime ?? 'application/octet-stream';
          modalData.document.downloadSrc = url;

          if (contentType === acceptedMimeTypes.pdf) {
            modalData.document.src = url + '#toolbar=0';
          } else if(acceptedMimeTypes.docs.includes(contentType)) {
            modalData.document.src = 'https://officeapps.live.com/op/embed.aspx?src=' + encodeURIComponent(url);
          } else if(mime.match(acceptedMimeTypes.image)) {
            modalData.document.imgSrc = url;
            modalData.imgViewer.images.push({
              thumbnail: this.modalData.document.imgSrc,
              source: this.modalData.document.imgSrc,
              alt: this.modalData.document.name,
            });
          } else {
            this.downloadFile();
          }

          modalData.show = true;
          this.modalData = modalData;

          this.disableDownload = false;
        })
        .catch((error) => {
          console.log(`Failed to download student document`, error);
          this.disableDownload = false;

          if (error.response) {
            const { response } = error;
            return this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Failed to download file.',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: response.data.message || 'An unknown error has occurred.',
              },
            });
          }

          return this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to download file.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error.message,
            },
          });
        });
    },
    expandPreviewModal() {
      this.modalData.size = this.modalData.size === 'xl' ? 'lg' : 'xl';
      this.modalData.expandIcon = this.modalData.expandIcon === 'Maximize2Icon' ? 'Minimize2Icon' : 'Maximize2Icon';
    },
    downloadFile() {
      const url = this.modalData.document.downloadSrc;
      if(!url) return;

      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Please wait a moment...',
          icon: 'DownloadIcon',
          variant: 'success',
          text: 'Your download till start shortly...',
        },
      });

      fetch(url).then(res => res.blob()).then(blob => {
        fileDownload(blob, this.modalData.document.name);
      }).catch((err) => {
        // sweetalert here
        console.error('Failed to download file:', err);
      });
    },
  },
  setup(props) {
    const documentData = ref(null);
    const STUDENT_APP_STORE_MODULE_NAME = 'app-student';

    // Register module
    if (!store.hasModule(STUDENT_APP_STORE_MODULE_NAME)) {
      store.registerModule(STUDENT_APP_STORE_MODULE_NAME, studentStoreModule);
    }

    const refetchData = () => {
      store
        .dispatch('app-student/fetchStudentDocuments', {
          id: router.currentRoute.params.id,
          queryParams: {
            category: props.category,
            limit: 50,
            sortBy: 'createdAt:desc',
          },
        })
        .then((response) => {
          documentData.value = response.data.results;
          console.log(`documents`, response.data);
        })
        .catch((error) => {
          if (error.response.status === 404) {
            documentData.value = undefined;
          }
        });
    };

    refetchData();

    return {
      documentData,
      moment,
      title,
      refetchData,
    };
  },
};
</script>

<style lang="scss">
.td-img-holder {
  width: 15%;
  max-width: 15%;
}

.td-img-b-img {
  box-shadow: 4px 4px 3px 0 #ea5455;
  width: 100%;
}

.td-doc-holder {
  vertical-align: top;
  width: 85%;
  max-width: 85%;
}

.td-doc-doc-name {
  max-width: 290px;
}

.audio-holder {
  padding-top: 4px;
}

.audio-holder audio {
  height: 32px;
}

iframe.doc-preview {
  height: 500px;
  overflow: scroll;
  border: 1px solid #fff;
}

.audio-holder audio::-webkit-media-controls-panel {
  background-color: #050056;
}
.audio-holder audio::-webkit-media-controls-mute-button {
  background-color: #ea5455;
  border-radius: 100%;
}
.audio-holder audio::-webkit-media-controls-play-button {
  background-color: #ea5455;
  border-radius: 100%;
  color: white;
}
.audio-holder audio::-webkit-media-controls-timeline-container {
  background-color: #ea5455;
}
.audio-holder audio::-webkit-media-controls-current-time-display {
  color: #fff;
}
.audio-holder audio::-webkit-media-controls-time-remaining-display {
  color: #fff;
}
.audio-holder audio::-webkit-media-controls-timeline {
  background-color: #ea5455;
  border-radius: 25px;
  margin-left: 10px;
  margin-right: 10px;
}
.audio-holder audio::-webkit-media-controls-volume-slider {
  background-color: #ea5455;
  border-radius: 25px;
  padding-left: 8px;
  padding-right: 8px;
  margin-right: 10px;
}
.viewer-wrapper {
  position: relative;
  background: #333;
  height: 100%;
}
.methods {
  margin-bottom: 1em;
  flex-wrap: wrap;
  & > * {
    margin-right: 0.75rem;
  }
}
.options-panel {
  .panel-block {
    padding: 0;
    .checkbox {
      display: block;
      width: 100%;
      margin: 0;
      padding: 0.5em 0.75em;
    }
  }
}
.viewer {
  height: 100%;
  .images {
    visibility: hidden;
    height: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 5px;
    .image-wrapper {
      display: inline-block;
      width: calc(33% - 20px);
      margin: 5px 5px 0 5px;
      .image {
        width: 100%;
        cursor: pointer;
        display: inline-block;
      }
    }
  }
}

.viewer-container {
  margin-top: 55px;
  margin-bottom: 100px;
}


</style>
